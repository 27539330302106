import cn from 'classnames';

import Spacer from '@/components/brochureV2/layout/Spacer/Spacer';

import HtmlTagWrapper from '../HtmlTagWrapper/HtmlTagWrapper';

import type {EyebrowProps} from './types';

/**
 * @deprecated
 */
export default function Eyebrow({
  children,
  className = '',
  spacerSize = 'xs',
  tag = 'p',
}: EyebrowProps) {
  return (
    <>
      <HtmlTagWrapper
        className={cn(
          'default:md:text-t7 default:font-bold default:uppercase tracking-wide',
          className,
        )}
        tag={tag}
      >
        {children}
      </HtmlTagWrapper>
      <Spacer size={spacerSize} />
    </>
  );
}
